import React from "react"
import loadable from "@loadable/component"
import { Link } from "gatsby"
import tw, { styled } from "twin.macro"
import { ButtonLink, P, H2, H3, HeaderDivider } from "./page-elements"
import Thumbnail from "./thumbnail"
const Section = loadable(() => import("./shared/section"))

interface Highlight {
  title: string
  src: string
  alt: string
  text: string
  link: string
}

const Content = {
  title: "Past Projects",
  text:
    "Glacier Painting will provide an unparalleled understanding, in-depth product knowledge, expertise, and thoughtful customer service to give you the best experience with your Madison Home Improvement Project, Commercial Painting Project, & or Wood Refinishing Project.",
  highlights: [
    {
      title: "Exterior Painting",
      text: "View Exterior Painting Projects",
      src: "gallery-interior-exterior-1aftrhouse.jpg",
      alt: "Exterior Painting",
      link: "/portfolio#residential",
    },
    {
      title: "Interior Painting",
      text: "View Our Interior Painting Work",
      src: "gallery-interior-exterior-9aftr.jpg",
      alt: "TODO",
      link: "/portfolio#residential",
    },
    {
      title: "Commercial Painting",
      text: "View Our Commercial Projects",
      src: "gallery-uhaul-uhaul4.jpg",
      alt: "TODO",
      link: "/portfolio#uhaul",
    },
    {
      title: "Residential Painting",
      text: "View Our Residential Projects",
      src: "gallery-interior-exterior-aftrhouse.jpg",
      alt: "TODO",
      link: "/portfolio#residential",
    },
    {
      title: "Wood Restoration",
      text: "View Our Wood Restoration Projects",
      src: "gallery-wood-3aftr.jpg",
      alt: "TODO",
      link: "/portfolio#wood",
    },
    {
      title: "Staining & Varnishing",
      text: "View Staining & Varnishing Work",
      src: "gallery-wood-house3.jpg",
      alt: "TODO",
      link: "/portfolio#wood",
    },
  ],
  button: {
    url: "/portfolio",
    text: "View All Projects",
  },
}

const GalleriesSection = () => {
  return (
    <Section classes="flex-row bg-aqua" size="lg">
      <div className="w-full py-3 md:py-6">
        <div className="flex flex-col md:flex-row w-full justify-between mb-4">
          <div className="flex flex-col md:w-1/2 px-1">
            <H2 className="text-center md:text-left" color="light">
              {Content.title}
            </H2>
            <HeaderDivider className="mx-auto md:ml-0" />
            <SectionText color="light">{Content.text}</SectionText>
          </div>
          <div className="md:w-1/2 px-1 flex items-center justify-center md:justify-end">
            <ButtonLink to={Content.button.url}>
              {Content.button.text}
            </ButtonLink>
          </div>
        </div>
        <Galleries>
          {Content.highlights.map(
            ({ title, text, src, alt, link }: Highlight) => (
              <BlockWrapper to={link}>
                <Block>
                  <FancyImage src={src} alt={alt} />
                  <Overlay />
                  <TextOverlay>
                    <OverlayTitle>{title}</OverlayTitle>
                    <OverlayText color="light">{text}</OverlayText>
                  </TextOverlay>
                </Block>
              </BlockWrapper>
            )
          )}
        </Galleries>
      </div>
    </Section>
  )
}

const Galleries = tw.div`
  grid
  gap-2
  grid-cols-1 md:grid-cols-3
  justify-start
  w-full
  max-w-screen-xl
`

const Overlay = tw.div`
  bg-primary
  opacity-0
  h-full
  w-full
  absolute
  top-0
  duration-200 
  ease-in-out
`

const FancyImage = styled(Thumbnail)`
  ${tw`
    absolute
    h-full
    w-full
    scale-0
    transform
    duration-200 
    ease-in-out
  `}
`

const TextOverlay = styled.div`
  ${tw`
    absolute
    h-16
    top-0
    bottom-0
    m-auto
    w-full
    opacity-0
    duration-200 
    ease-in-out
  `}
  transform: translateY(8px);
`

const OverlayTitle = styled(H3)`
  ${tw`
    text-center
    text-white
    text-2xl
    uppercase
    font-semibold
  `}
`

const OverlayText = styled(P)`
  ${tw`
    text-center
    text-white
  `}
`

const SectionText = styled(P)`
  ${tw`
    w-full
    px-page-gutter
    md:px-0
  `}
`

const BlockWrapper = styled(Link)`
  ${tw`
    overflow-hidden
    mx-2
    md:mx-0
    md:p-1
  `}
`

const Block = styled.div`
  ${tw`
    h-full
    w-full
    overflow-hidden
    relative
    mx-auto
    cursor-pointer
  `}

  /* height: 300px; */
  /* max-width: 300px; */

  @media (min-width: 768px) {
    height: 175px;
    max-width: 430px;
  }

  @media (max-width: 768px) {
    ${Overlay} {
      ${tw`
      opacity-75
    `}
    }
    ${FancyImage} {
      ${tw`
        scale-110
      `}
    }
    ${TextOverlay} {
      ${tw`
      h-16
      opacity-100
    `}

      transform: translateY(0);
    }
  }

  &:hover ${Overlay} {
    ${tw`
      opacity-75
    `}
  }

  &:hover ${FancyImage} {
    ${tw`
      scale-110
    `}
  }

  &:hover ${TextOverlay} {
    ${tw`
      h-16
      opacity-100
    `}

    transform: translateY(0);
  }
`

export default GalleriesSection
